<template>
    <v-card flat class="pa-md-8 demo-screen-layout" width="100%" height="100%">
        <v-row class="d-block text-center">
            <v-btn v-if="!sessionSatarted" color="yellow" class="no-shadow mb-10" :loading="loading" @click="joinEvent()">
                {{ $t('join_sessn') }}
            </v-btn>
            <v-btn
                v-else
                color="red"
                class="mb-10 no-shadow"
                @click="leaveEvent()"
                >
                <v-icon>mdi-close</v-icon>
                {{ $t('end_sessn') }}
            </v-btn>
        </v-row>
        <v-row no-gutters justify="center">
            <v-col cols="6">
                <fullscreen ref="fullscreen">
                    <v-card :color="!remoteStreams.length ? 'black' : null" class="mx-4">
                        <v-card-title
                            v-show="profileStream"
                            class="client-video"
                        >
                            <AgoraVideo
                                v-if="profileStream"
                                :stream="profileStream"
                                :dom-id="profileStream.getId()"
                            />
                        </v-card-title>
                        <v-card-title
                            v-show="!profileStream"
                            class="client-video"
                        >
                            <img
                                width="100"
                                height="100"
                                class="rounded profile-avatar"
                                :src="profileImage"
                            />
                        </v-card-title>
                        <v-card-text
                        :class="
                            fullscreen
                            ? 'grow host-full-video pa-0 ma-0'
                            : 'grow host-video pa-0 ma-0'
                        "
                        >
                            <AgoraVideo
                                v-if="remoteStreams.length"
                                :stream="remoteStreams[0]"
                                :dom-id="remoteStreams[0].getId()"
                                :fullscreen="fullscreen"
                                :is-client="false"
                            />
                        </v-card-text>
                        <v-card-actions class="pb-5">
                        <v-row
                            id="custom-disabled"
                            justify="space-between"
                            align="center"
                        >

                            <v-col class="tooltip-buttons">
                            <!-- Voice mute/unmute buttton -->
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    color="white"
                                    class="no-shadow ma-1"
                                    :disabled="!disableJoin"
                                    height="48"
                                    width="48"
                                    x-small
                                    v-on="on"
                                    @click="muteAudio"
                                >
                                    <img
                                    height="25"
                                    width="25"
                                    :src="
                                        isAudioOn
                                        ? '/images/icon/mic.png'
                                        : '/images/icon/mic_off.png'
                                    "
                                    />
                                </v-btn>
                                </template>
                                <span>{{ isAudioOn ? $t('unmute') : $t('mute') }}</span>
                            </v-tooltip>
                            <!-- Voice mute/unmute buttton -->

                            <!-- Video on/off buttton -->
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    color="white"
                                    class="no-shadow ma-1"
                                    :disabled="!disableJoin"
                                    height="48"
                                    width="48"
                                    x-small
                                    v-on="on"
                                    @click="muteVideo"
                                >
                                    <img
                                    height="25"
                                    width="25"
                                    :src="
                                        isVideoOn
                                        ? '/images/icon/cam.png'
                                        : '/images/icon/cam_off.png'
                                    "
                                    />
                                </v-btn>
                                </template>
                                <span>{{
                                isVideoOn ? $t('camera_on') : $t('camera_off')
                                }}</span>
                            </v-tooltip>
                            <!-- Video on/off buttton -->

                            <!-- Share Screen on/off buttton -->
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    color="white"
                                    class="no-shadow ma-1"
                                    :disabled="!disableJoin"
                                    :loading="shareLoading"
                                    height="48"
                                    width="48"
                                    x-small
                                    v-on="on"
                                    @click="screenShare"
                                >
                                    <img
                                    height="25"
                                    width="25"
                                    :src="
                                        isScreenShare
                                        ? '/images/icon/share.png'
                                        : '/images/icon/share_off.png'
                                    "
                                    />
                                </v-btn>
                                </template>
                                <span>{{
                                isScreenShare
                                    ? $t('share_screen_on')
                                    : $t('share_screen_off')
                                }}</span>
                            </v-tooltip>
                            <!-- Share Screen on/off buttton -->

                            <!--Chat buttton -->
                            <v-menu
                                v-model="chatDialog"
                                :close-on-click="false"
                                :close-on-content-click="false"
                                :nudge-width="200"
                                top
                                offset-y
                                :nudge-top="10"
                            >
                                <template #activator="{ on: menu, attrs }">
                                <v-tooltip bottom>
                                    <template #activator="{ on: tooltip }">
                                    <v-btn
                                        color="white"
                                        class="no-shadow ma-1"
                                        :disabled="!disableJoin"
                                        height="48"
                                        width="48"
                                        x-small
                                        v-bind="attrs"
                                        v-on="{ ...tooltip, ...menu }"
                                    >
                                        <v-icon v-if="chatDialog" color="black">
                                        mdi-close
                                        </v-icon>
                                        <v-icon v-else color="black">
                                        mdi-message
                                        </v-icon>
                                    </v-btn>
                                    </template>
                                    <span>Chat</span>
                                </v-tooltip>
                                </template>
                                <!-- Chat Component -->
                                <AgoraChat
                                v-if="chatDialog"
                                :chat-dialog="chatDialog"
                                :options="chatOption"
                                @scroll-bottom="scrollToBottom()"
                                @showAlert="showAlert"
                                />
                            </v-menu>
                            <!--Chat buttton -->

                            <!--Fullscreen on/off buttton -->
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    color="white"
                                    class="no-shadow ma-1 mr-3"
                                    :disabled="!disableJoin"
                                    height="48"
                                    width="48"
                                    x-small
                                    v-on="on"
                                    @click="toggleScreen"
                                >
                                    <v-icon v-if="fullscreen" color="black">
                                    mdi-fullscreen
                                    </v-icon>
                                    <v-icon v-else color="black">
                                    mdi-fullscreen-exit
                                    </v-icon>
                                </v-btn>
                                </template>
                                <span>{{
                                fullscreen ? $t('full_screen_on') : $t('full_screen_off')
                                }}</span>
                            </v-tooltip>
                            <!--Fullscreen on/off buttton -->
                            </v-col>
                        </v-row>
                        </v-card-actions>
                    </v-card>
                </fullscreen>
            </v-col>
        </v-row>
        <v-snackbar v-model="show" :timeout="5000" :color="isError ? 'red' : 'success'">
            {{ text }}
        </v-snackbar>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { StripeElementCard } from '@vue-stripe/vue-stripe'
import { STRIPE_PUBLISHABLE_KEY } from '../consts'

//utils
import i18n from '@/utils/i18n.js'

// graphql
import { MARK_SESSION_COMPLETED } from '@/graphql/sessions'
import { GET_VIDEO_DEMO_TOKEN } from '@/graphql/video'

// Video
import RTCClient from '@/agora/video/rtcClient'
import { log } from '@/agora/video/log'
import AgoraVideo from '@/agora/video/Video'

// Chat
import AgoraChat from '@/agora/chat/Chat'

export default {
    components: {
        AgoraVideo,
        AgoraChat,
        StripeElementCard,
    },
    props: {
        session: {
            type: Object,
            default: () => {},
            required: true,
        },
    },
    data() {
        return {
            stripePublishKey: STRIPE_PUBLISHABLE_KEY,
            option: {
                appid: '5042fbb7676e479c9f81f553b8b59620',
                uid: null,
                channel: null,
                host: true,
            },
            chatOption: {
                appid: '5042fbb7676e479c9f81f553b8b59620',
                token: null,
                uid: null,
                channel: null,
                userName: null,
                msgChannel: null,
            },
            extendTimeMin: 0,
            extendTimePrice: 0,
            disableJoin: false,
            localStream: null,
            profileStream: null,
            remoteStreams: [],
            streamId: null,
            loading: false,
            error: null,
            show: false,
            text: null,
            isError: false,
            chatDialog: false,
            shareLoading: false,
            fullscreen: false,
            isScreenShare: false,
            isPermissionError: null,
            selectedSlot: null,
            isShowCard: false,
            userCardDetails: [],
            selectedCard: null,
            enterFullScreen: false,
            disableJoinButton: true,
            intervalToken: null,
            addCardDialog: false,
            stripeError: null,
            extendError: null,
            extendLoading: false,
            endTime: null,
            sessionSatarted: false,
            increaseMin: 0,
            increaseLoading: false,
            extendSessionAlert: false,
        }
    },
    mounted() {
        // Set session duration time when page render
        this.option.channel = this.$route.params.channel
    },
    computed: {
        ...mapGetters(['profileImage', 'user', 'jwt']),
        isAudioOn() {
            return this.localStream && this.localStream.isAudioOn() ? true : false
        },
        isVideoOn() {
            return this.localStream && this.localStream.isVideoOn() ? true : false
        },
    },
    watch: {},
    created() {
        document.addEventListener('fullscreenchange', () => {
            if (this.enterFullScreen) {
                this.fullscreen = false
            }
            this.enterFullScreen = !!this.fullscreen
        })

        this.rtc = new RTCClient()
        let { rtc } = this
        rtc.on('stream-added', evt => {
            let { stream } = evt
            log('[agora] [stream-added] stream-added', stream.getId())
            rtc.client.subscribe(stream)
        })

        rtc.on('stream-subscribed', evt => {
            let { stream } = evt
            log('[agora] [stream-subscribed] stream-added', stream.getId())
            if (!this.remoteStreams.find(it => it.getId() === stream.getId())) {
                this.remoteStreams.push(stream)
            }
        })

        rtc.on('stream-removed', evt => {
            let { stream } = evt
            log('[agora] [stream-removed] stream-removed', stream.getId())
            this.remoteStreams = this.remoteStreams.filter(
                it => it.getId() !== stream.getId()
            )
        })

        rtc.on('peer-online', evt => {
            log(`Peer ${evt.uid} is online`)
        })

        rtc.on('peer-leave', evt => {
            log(`Peer ${evt.uid} already leave`)
            this.remoteStreams = this.remoteStreams.filter(
                it => it.getId() !== evt.uid
            )
        })

    },
    methods: {
        handleError(err) {
            console.log("Error: ", err);
        },
        /**
         * Join The Channel
         */
        async joinEvent(isAudio = true, isVideo = true, isScreen = false) {
            this.disableSessionLists(true)
            if (isScreen) {
                this.isScreenShare = true
                this.shareLoading = true
                isAudio = true
                isVideo = false
            } else {
                this.isScreenShare = false
                this.loading = true
                isAudio = true
                isVideo = true
            }
            this.error = null
            this.loading = false
            this.shareLoading = false
            const {
                data: { generateVideoDemo },
            } = await this.$apollo.mutate({
                mutation: GET_VIDEO_DEMO_TOKEN,
                variables: {
                    authData: {
                        email: this.user.email,
                        accesstoken: this.jwt,
                        demostring: this.option.channel,
                    },
                },
            })
            if (generateVideoDemo.success) {
                this.loading = false
                this.shareLoading = false

                // Video Options
                this.option.channel = this.option.channel
                this.option.token = generateVideoDemo.videotoken
                this.option.uid = this.user.id
                this.option.userName = `${this.user.firstName} ${this.user.lastName}`

                if (!this.option.appid) {
                    this.disableSessionLists(false)
                    this.judge('Appid')
                    return
                }
                if (!this.option.channel) {
                    this.disableSessionLists(false)
                    this.judge('Channel Name')
                    return
                }
                await this.rtc
                    .joinChannel(this.option)
                    .then(() => {
                        log('Join Success')
                        this.rtc
                            .publishStream({
                                audio: isAudio,
                                video: isVideo,
                                screen: isScreen,
                            })
                            .then(stream => {
                                log('Publish Success', stream)
                                this.localStream = stream
                                if(!isScreen) {
                                    this.profileStream = stream
                                }
                                // set video resoultion 3840 × 2160, Frame rate: 60, Bitrate: 13500
                                if (!this.isScreenShare) {
                                    this.localStream.setVideoProfile('4K_3')
                                }
                            })
                            .catch(err => {
                                this.isPermissionError = i18n.t('permission_error_txt')
                                log('publish local error', err)
                            })
                    })
                    .catch(err => {
                        log('join channel error', err)
                    })
                this.sessionSatarted = true
                this.disableJoin = true
                this.fullscreen = false
                this.localStream.unmuteAudio()
            } else {
                this.disableSessionLists(false)
                this.error = this.$t(generateVideoAuth.error)
                this.loading = false
                this.shareLoading = false
                await delay(2000)
                this.error = null
            }

        },

        /**
         * Leave The Channel
         */
        leaveEvent() {
            this.disableSessionLists(false)
            this.disableJoin = false
            this.rtc
                .leaveChannel()
                .then(() => {
                    log('Leave Success')
                })
                .catch(err => {
                    log('leave error', err)
                })
            this.localStream = null
            this.profileStream = null
            this.isPermissionError = null
            this.remoteStreams = []
            this.chatDialog = false
            this.sessionSatarted = false
            this.$store.commit('setChatMessages', null)
            this.increaseMin = 0
            this.extendSessionAlert = false
            window.rtm = null
        },
        /**
         * mark session completed
         */
        async markedSessionCompleted() {
            try {
                const {
                    data: { markSessionCompleted },
                } = await this.$apollo.mutate({
                    mutation: MARK_SESSION_COMPLETED,
                    variables: {
                        sessionData: {
                            email: this.user.email,
                            accesstoken: this.jwt,
                            sessionid: Number(this.session.id),
                        },
                    },
                })

                if (markSessionCompleted.success) {} else {}
            } catch (e) {
                this.loading = false
                console.error(e) // eslint-disable-line
            }
        },

        /**
         * Verify the Details
         */
        judge(detail) {
            this.error = `Please enter the ${detail}`
        },

        /**
         * Screen Share
         */
        screenShare() {
            this.isScreenShare = this.isScreenShare ? false : true
            // this.disableSessionLists(false)
            // this.disableJoin = false
            this.localStream = null
            // this.isPermissionError = null
            // this.remoteStreams = []
            // this.chatDialog = false
            // this.sessionSatarted = false
            // this.$store.commit('setChatMessages', null)
            // window.rtm = null
            if(this.isScreenShare) this.joinEvent(true, true, this.isScreenShare)
            // this.disableJoin = true
            // this.chatDialog = false
        },

        /**
         * Mute Audio
         */
        muteAudio() {
            this.localStream.isAudioOn() ?
                this.localStream.muteAudio() :
                this.localStream.unmuteAudio()
        },

        /**
         * Mute Video
         */
        muteVideo() {
            this.localStream.isVideoOn() ?
                this.localStream.muteVideo() :
                this.localStream.unmuteVideo()
        },

        /**
         * Alert
         */
        showAlert({ message, isError = false }) {
            this.show = true
            this.text = message
            this.isError = isError
        },

        /**
         * Toggle Screen
         */
        toggleScreen() {
            this.$refs['fullscreen'].toggle()
            this.fullscreen = this.fullscreen ? false : true
            this.enterFullScreen = false
        },

        /**
         * Disable The Session Lists
         */
        disableSessionLists(val) {
            this.$emit('update:is-list-disabled', val)
        },

        /**
         * Move chat scroll to bottom
         */
        scrollToBottom() {
            let chatmessages = document.querySelector('#chatmessages')
            this.$nextTick(() => {
                // DOM updated
                chatmessages.scrollTop = chatmessages.scrollHeight
            })
        },

        /**
         * Show card input for increasing Session
         */
        showCardDetails() {
            if (this.extendTimeMin === 0 && this.extendTimePrice === 0) {
                this.$toasted.error('There is not increase time')
                return
            } else if (this.extendTimeMin != 0 && this.extendTimePrice != 0) {
                this.isShowCard = true
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.demo-screen-layout {
    margin-top: 100px;
}

#custom-disabled .v-btn--disabled {
    background-color: white !important;
}

.host-video {
    height: 55vh;
}

.client-video {
    position: absolute;
    z-index: 2;
    top: 10px;
    left: 10px;
}

.fullscreen {
    background: none !important;
}

.host-full-video {
    height: 90vh;
}
</style>

<style lang="scss">
.session-time-slot.v-btn-toggle--group>.v-btn.v-btn.v-btn--active {
    background-color: $secondary !important;
    color: $white !important;
    border: none;
}

.session-time-slot.v-btn-toggle--group>.v-btn.v-btn {
    background-color: white !important;
    border: 1px dashed #d9d9d9;
}

.user-card.v-sheet--outlined {
    border: thin solid #ff4200;
}

.v-list.v-select-list .v-list-item--active {
    background-color: aliceblue;
}

.profile-avatar {
    object-fit: cover;
}

.session-countdown {
    color: white;
    position: absolute;
    top: 40%;
    right: 0;
    left: 0;
    z-index: 1000;
    justify-content: center;
}

.session-countdown-time {
    font-size: 40px;
    display: flex;
    justify-content: center;
}

.tooltip-buttons {
    display: flex;
    justify-content: flex-end;
}

.increase_btn {
    background-color: #f7b500;
}
</style>
