var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-md-8 demo-screen-layout",attrs:{"flat":"","width":"100%","height":"100%"}},[_c('v-row',{staticClass:"d-block text-center"},[(!_vm.sessionSatarted)?_c('v-btn',{staticClass:"no-shadow mb-10",attrs:{"color":"yellow","loading":_vm.loading},on:{"click":function($event){return _vm.joinEvent()}}},[_vm._v(" "+_vm._s(_vm.$t('join_sessn'))+" ")]):_c('v-btn',{staticClass:"mb-10 no-shadow",attrs:{"color":"red"},on:{"click":function($event){return _vm.leaveEvent()}}},[_c('v-icon',[_vm._v("mdi-close")]),_vm._v(" "+_vm._s(_vm.$t('end_sessn'))+" ")],1)],1),_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('fullscreen',{ref:"fullscreen"},[_c('v-card',{staticClass:"mx-4",attrs:{"color":!_vm.remoteStreams.length ? 'black' : null}},[_c('v-card-title',{directives:[{name:"show",rawName:"v-show",value:(_vm.profileStream),expression:"profileStream"}],staticClass:"client-video"},[(_vm.profileStream)?_c('AgoraVideo',{attrs:{"stream":_vm.profileStream,"dom-id":_vm.profileStream.getId()}}):_vm._e()],1),_c('v-card-title',{directives:[{name:"show",rawName:"v-show",value:(!_vm.profileStream),expression:"!profileStream"}],staticClass:"client-video"},[_c('img',{staticClass:"rounded profile-avatar",attrs:{"width":"100","height":"100","src":_vm.profileImage}})]),_c('v-card-text',{class:_vm.fullscreen
                        ? 'grow host-full-video pa-0 ma-0'
                        : 'grow host-video pa-0 ma-0'},[(_vm.remoteStreams.length)?_c('AgoraVideo',{attrs:{"stream":_vm.remoteStreams[0],"dom-id":_vm.remoteStreams[0].getId(),"fullscreen":_vm.fullscreen,"is-client":false}}):_vm._e()],1),_c('v-card-actions',{staticClass:"pb-5"},[_c('v-row',{attrs:{"id":"custom-disabled","justify":"space-between","align":"center"}},[_c('v-col',{staticClass:"tooltip-buttons"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"no-shadow ma-1",attrs:{"color":"white","disabled":!_vm.disableJoin,"height":"48","width":"48","x-small":""},on:{"click":_vm.muteAudio}},'v-btn',attrs,false),on),[_c('img',{attrs:{"height":"25","width":"25","src":_vm.isAudioOn
                                    ? '/images/icon/mic.png'
                                    : '/images/icon/mic_off.png'}})])]}}])},[_c('span',[_vm._v(_vm._s(_vm.isAudioOn ? _vm.$t('unmute') : _vm.$t('mute')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"no-shadow ma-1",attrs:{"color":"white","disabled":!_vm.disableJoin,"height":"48","width":"48","x-small":""},on:{"click":_vm.muteVideo}},'v-btn',attrs,false),on),[_c('img',{attrs:{"height":"25","width":"25","src":_vm.isVideoOn
                                    ? '/images/icon/cam.png'
                                    : '/images/icon/cam_off.png'}})])]}}])},[_c('span',[_vm._v(_vm._s(_vm.isVideoOn ? _vm.$t('camera_on') : _vm.$t('camera_off')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"no-shadow ma-1",attrs:{"color":"white","disabled":!_vm.disableJoin,"loading":_vm.shareLoading,"height":"48","width":"48","x-small":""},on:{"click":_vm.screenShare}},'v-btn',attrs,false),on),[_c('img',{attrs:{"height":"25","width":"25","src":_vm.isScreenShare
                                    ? '/images/icon/share.png'
                                    : '/images/icon/share_off.png'}})])]}}])},[_c('span',[_vm._v(_vm._s(_vm.isScreenShare ? _vm.$t('share_screen_on') : _vm.$t('share_screen_off')))])]),_c('v-menu',{attrs:{"close-on-click":false,"close-on-content-click":false,"nudge-width":200,"top":"","offset-y":"","nudge-top":10},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var menu = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"no-shadow ma-1",attrs:{"color":"white","disabled":!_vm.disableJoin,"height":"48","width":"48","x-small":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[(_vm.chatDialog)?_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-close ")]):_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-message ")])],1)]}}],null,true)},[_c('span',[_vm._v("Chat")])])]}}]),model:{value:(_vm.chatDialog),callback:function ($$v) {_vm.chatDialog=$$v},expression:"chatDialog"}},[(_vm.chatDialog)?_c('AgoraChat',{attrs:{"chat-dialog":_vm.chatDialog,"options":_vm.chatOption},on:{"scroll-bottom":function($event){return _vm.scrollToBottom()},"showAlert":_vm.showAlert}}):_vm._e()],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"no-shadow ma-1 mr-3",attrs:{"color":"white","disabled":!_vm.disableJoin,"height":"48","width":"48","x-small":""},on:{"click":_vm.toggleScreen}},'v-btn',attrs,false),on),[(_vm.fullscreen)?_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-fullscreen ")]):_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-fullscreen-exit ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.fullscreen ? _vm.$t('full_screen_on') : _vm.$t('full_screen_off')))])])],1)],1)],1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":5000,"color":_vm.isError ? 'red' : 'success'},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }